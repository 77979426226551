import type { Locale } from 'date-fns';
import { setDefaultOptions } from 'date-fns';
import { computed, reactive } from 'vue';

import { zendeskService } from '@exchange/libs/utils/zendesk/src';

import i18nService from './i18n.service';

interface NavigatorLanguage extends Navigator {
  /** cause https://www.w3.org/TR/html51/webappapis.html#navigatorlanguage-navigatorlanguage */ userLanguage?: string;
}
enum BrokerFormatLanguageCodes {
  english = 'eng',
  german = 'deu',
}

enum ISOFormatLanguageCodes {
  english = 'en',
  german = 'de',
}

export type ProLanguage = `${ISOFormatLanguageCodes}`;
export type BrokerLanguage = string;

interface LangsInfo {
  lang: ProLanguage;
}

export type LanguageListItem = { id: ProLanguage; name: string; icon: string };

const getNavigatorLanguage = () => {
  const [lang, locale] = ((navigator as NavigatorLanguage).userLanguage || navigator.language).replace('-', '_').toLowerCase().split('_');

  return { lang, locale };
};

class LangsInfoService {
  public readonly fallbackLang: ProLanguage = ISOFormatLanguageCodes.english;

  public languageList: Array<LanguageListItem> = [
    {
      id: ISOFormatLanguageCodes.english,
      name: 'English',
      icon: 'uk',
    },
    {
      id: ISOFormatLanguageCodes.german,
      name: 'Deutsch',
      icon: 'de',
    },
  ];

  private onSetLanguageCallbacks: Array<(lang: ProLanguage) => void> = [];

  public addOnSetLanguageCallback = (cb: (lang: ProLanguage) => void) => {
    this.onSetLanguageCallbacks.push(cb);
  };

  private getInitialLanguage = () => {
    const navLang = getNavigatorLanguage().lang;

    if (!navLang) {
      return this.fallbackLang;
    }

    return this.languageList.some((item) => item.id === navLang) ? (navLang as ProLanguage) : this.fallbackLang;
  };

  private langsInfo = reactive<LangsInfo>({
    lang: this.getInitialLanguage(),
  });

  public language = computed(() => this.langsInfo.lang);

  public dateFnsLocales = new Map<string, Locale>();

  private loadDateFnsLocaleAsync = async (locale: string) => {
    const finalLocale = locale === 'en' ? 'en-US' : locale;
    const l = this.dateFnsLocales.get(finalLocale);

    if (l) {
      setDefaultOptions({ locale: l });
      return;
    }

    const { default: ll }: { default: Locale } = await import(
      /* webpackExclude: /(\.js\.flow|\.d\.ts)$/, webpackChunkName: 'date-fns-locale-[request]' */ `date-fns/locale/${finalLocale}/index.js`
    );

    setDefaultOptions({ locale: ll });
    this.dateFnsLocales.set(finalLocale, ll);
  };

  public loadTranslations = async () => {
    i18nService.setI18nLocale(this.language.value);
    await this.loadDateFnsLocaleAsync(this.language.value);
  };

  public setLanguage = async (language: BrokerLanguage | ProLanguage) => {
    let ISOFormattedLanguage: ProLanguage;
    const isoSupportedList: Array<string> = Object.values(ISOFormatLanguageCodes);

    if (!isoSupportedList.includes(language)) {
      ISOFormattedLanguage = this.getISOFormatOrFallback(language);
    } else {
      ISOFormattedLanguage = language as ProLanguage;
    }

    if (ISOFormattedLanguage !== this.langsInfo.lang) {
      this.langsInfo.lang = ISOFormattedLanguage;

      await this.loadTranslations();
      zendeskService.setLocale(ISOFormattedLanguage);
    }

    this.onSetLanguageCallbacks.forEach((cb) => {
      if (typeof cb === 'function') {
        cb(ISOFormattedLanguage);
      }
    });
  };

  public getISOFormatOrFallback = (langCode: BrokerLanguage) => (langCode === BrokerFormatLanguageCodes.german ? ISOFormatLanguageCodes.german : ISOFormatLanguageCodes.english);

  public getBrokerFormatOrFallback = (langCode: ProLanguage) => (langCode === ISOFormatLanguageCodes.german ? BrokerFormatLanguageCodes.german : BrokerFormatLanguageCodes.english);
}

const lis = new LangsInfoService();

export default lis;
