import { formatDistanceStrict, format, isValid } from 'date-fns';

export const utcToLocal = (utcDateString: string | Date | number, formatString = 'fromNow') => {
  if (!isValid(new Date(utcDateString))) {
    return '–';
  }

  const dateString = typeof utcDateString === 'string' ? new Date(utcDateString) : utcDateString;

  if (formatString === 'fromNow') {
    return formatDistanceStrict(dateString, new Date());
  }

  return format(dateString, formatString);
};

export default utcToLocal;
