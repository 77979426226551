import { createI18n } from 'vue-i18n';

import de from './i18n/de.json';
import en from './i18n/en.json';

class I18nService {
  public i18nInstance = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    legacy: false,
    messages: {
      en,
      de,
    },
  });

  public setI18nLocale = (lang: string) => {
    // @ts-ignore locale is ref
    this.i18nInstance.global.locale.value = lang;
  };
}

const i18ns = new I18nService();

export default i18ns;
